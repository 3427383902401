import { NavBar } from '..';

export function Options() {
  return (
    <>
      <h1>Options Page placeholder</h1>;
      <NavBar />
    </>
  );
}
